export default {
	paymentInfoLoading: false,
	paymentInfoLoadingPromise: null,
	paymentInfoError: false,
	paymentInfo: null,

	totalCreditsAmount() {
		const parsedAmount = parseFloat(`${this.paymentInfo?.credits?.totalAmount?.units}.${this.paymentInfo?.credits?.totalAmount?.nanos}`)

		if (!isNaN(parsedAmount) && parsedAmount >= 0.01) {
			return parsedAmount
		} else {
			return 0
		}
	},

	hasNextAutoPayment() {
		return (
			this.paymentInfo &&
			this.paymentInfo.automaticPayment &&
			this.paymentInfo.automaticPayment.paymentDate !== "" &&
			this.paymentInfo.automaticPayment.amount > 0
		)
	},

	hasSavedPaymentMethod() {
		return this.paymentInfo && this.paymentInfo.methods && this.paymentInfo.methods.length > 0
	},
}
