import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import PaymentService from "../../services/payment"
import { makeCancelable } from "../../utils"
import contractNamespace from "../contract/namespace"
import { addPaymentInfoEnd, addPaymentInfoStart } from "../contract/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchPaymentInfoStart = actionsBuilder.createAction("fetchPaymentInfoStart", (state, loadingPromise) => {
	state[namespace].paymentInfo = null
	state[namespace].paymentInfoLoading = true
	state[namespace].paymentInfoLoadingPromise = loadingPromise

	state[namespace].paymentInfoError = false
})

export const fetchPaymentInfoEnd = actionsBuilder.createAction("fetchPaymentInfoEnd", (state, paymentInfo) => {
	if (paymentInfo) {
		state[namespace].paymentInfo = paymentInfo
	}

	state[namespace].paymentInfoError = !paymentInfo

	state[namespace].paymentInfoLoading = false
	state[namespace].paymentInfoLoadingPromise = null
})

export const PaymentActions = {
	fetchPaymentInfo: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { paymentInfoLoadingPromise } = state[namespace]

		if (paymentInfoLoadingPromise) {
			paymentInfoLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(PaymentService.fetchPaymentInfo(current.contractID))
		dispatch(fetchPaymentInfoStart(cancelablePromise))
		dispatch(addPaymentInfoStart({ loadingPromise: cancelablePromise, contractID: current.contractID }))

		try {
			const paymentInfo = await cancelablePromise.promise

			dispatch(fetchPaymentInfoEnd(paymentInfo))
			dispatch(addPaymentInfoEnd({ paymentInfo, contractID: current.contractID }))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchPaymentInfoEnd())
				dispatch(addPaymentInfoEnd({ contractID: current.contractID }))
			}
		}
	},
	setPaymentInfoFromCurrentContract: () => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (!current) {
			return
		}

		dispatch(fetchPaymentInfoEnd(current.paymentInfo))
	},
}

export const actions = actionsBuilder.exportActions()
